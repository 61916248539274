import { CreateActionParams } from '../actions';
import { EventNames } from '../../core/fedops';
import { createInitialState } from '../../utils';
import { checkoutTipsTipModalLoadedOnLiveSiteSrc130Evid31 } from '@wix/bi-logger-ecom-platform-data/v2';

export type InitializeWidget = () => void;

export function createInitializeWidgetAction({
  context,
  getControllerState,
}: CreateActionParams): InitializeWidget {
  return async () => {
    const { fedopsLogger, api, biLogger } = context;
    fedopsLogger?.interactionStarted(EventNames.WidgetInitialized);
    const [state, setState] = getControllerState();
    const { checkoutSlotProps } = state;

    if (!checkoutSlotProps) {
      throw new Error('Checkout slot props are missing');
    }

    const [checkoutInfo, tipSettings] = await Promise.all([
      api.getCheckoutInfo({
        checkoutId: checkoutSlotProps.checkoutId,
      }),
      api.getTipSettings({}),
    ]);

    const initialState = await createInitialState({
      tipSettings,
      checkoutInfo,
      context,
    });

    if (initialState.presetInfo?.presets.length) {
      biLogger?.update(initialState);

      biLogger?.report(
        checkoutTipsTipModalLoadedOnLiveSiteSrc130Evid31({
          defaultTipValue: Number(
            tipSettings?.presets?.find((preset) => preset.default)?.value || 0,
          ),
          tipType: tipSettings?.tipType,
          isCustomTip: !!tipSettings?.allowCustomAmount,
        }),
      );
    }

    setState(initialState);

    fedopsLogger?.interactionEnded(EventNames.WidgetInitialized);
  };
}
